export default new class Sabores {
    constructor(){
        this.stackFlavors = []
        this.subscribeEvents()
    }

    subscribeEvents() {
        document.addEventListener('readystatechange', evt => {
            if (document.readyState === 'interactive')  {
                const buttons = document.querySelectorAll('[data-pack]');
                this.setDisableElement(buttons)
            }
        })

        document.addEventListener('click', evt => {
            const target = evt.target.closest('[data-flavor] button');
            if (target) {
                this.addFlavor(target.parentElement)
            }
        })

        Array.from(["keyup", "change"]).forEach( e => {
            document.addEventListener(e, evt => {
                const target = evt.target.closest('[data-flavor] [data-quantity]');
                if (target) {
                    setTimeout(() => {
                        const name = target.parentElement.querySelector('[data-name]').innerText
                        const item = this.getPosByName(name)
                        const quantity = target.value
                        if (item != -1) {
                            this.updateRecord(item, quantity)   
                        } else {
                            if ( item == -1 && quantity != 0) {
                                this.addFlavor(target.parentElement)
                            } else {
                                if ( quantity == 0 ) { 
                                    this.stackFlavors = this.stackFlavors.filter( (f, i) => i != item ),
                                    target.value = ''
                                }
                            }
                        }
        
                        this.setListInModalForm(document.querySelector('#form-consulta'))
                        const buttons = document.querySelectorAll('[data-pack]');
                        if (this.getTotalFlavors() >= 6){
                            this.setEnableElement(buttons)
                        }
                        else
                            this.setDisableElement(buttons)
                    }, 500)
                }
                console.log(this.stackFlavors)            
            })
        } )
    }

    addFlavor(elem) {
        const name = elem.querySelector('[data-name]').innerText
        const quantity = elem.querySelector('[data-quantity]').value

        // if (this.getTotalFlavors() + +quantity > 6){
        //     alert('Ha alcanzado/sobrepasado el limite de sabores')
        //     elem.querySelector('[data-quantity]').value = ''
        //     return;
        // }

        //Serach for flavor and get position if exist
        let item = this.getPosByName(name)

        if (quantity > 0){
            if ( item == -1 )
                this.stackFlavors.push({ name: name, quantity: +quantity})
            else {
                //Modify flavor quantity if not exceed total of 6
                this.updateRecord(item, quantity)
            }
        }else{
            window.alert('Debe especificar una cantidad para cada sabor')
        }

        const buttons = document.querySelectorAll('[data-pack]');
        if (this.getTotalFlavors() > 0)
            this.setEnableElement(buttons)
        this.setListInModalForm(document.querySelector('#form-consulta'))
    }

    isInStack(name)  {
        return this.stackFlavors.some( flavor => flavor.name  === name )
    }

    getPosByName(name) {
        return this.stackFlavors.findIndex( flavor => flavor.name  === name )
    }

    getTextFromStack() {
        const flavors = this.stackFlavors.flatMap( flavor => `Sabor:  ${flavor['name']}  Cantidad: ${flavor['quantity']}` )
        return flavors.toString().replaceAll(',', '\n')
    }

    getTotalFlavors() {
        const sum = this.stackFlavors.reduce((accumulator, object) => accumulator + object.quantity, 0);
        return +sum
    }

    updateRecord(item, quantity) {
        this.stackFlavors[item].quantity = +quantity
    }

    setListInModalForm(form) {
        const field = `<div class="form-group row">
        <div class="col-12">
            <label>Sabores</label>
            <textarea disabled name="sabores" class="form-control" rows="5">
                ${ this.getTextFromStack() }
            </textarea>
        </div>
    </div>`

        if (form && !form.querySelector('[name=sabores]')) {
            form.querySelector('#sabores').innerHTML = field
        }else{
            form.querySelector('[name=sabores]').value = this.getTextFromStack()
        }
    }

    setDisableElement(arr) {
        arr.forEach(element => {
            if (element.nodeType == 1) {
                if (element.nodeName == 'BUTTON') {
                    element.setAttribute('disabled', true)
                }
    
                if (element.nodeName == 'A') {
                    element.classList.add('disabled')
                }
            }
        });
    }

    setEnableElement(arr) {
        arr.forEach(element => {
            if (element.nodeType == 1) {
                if (element.nodeName == 'BUTTON' && element.hasAttribute('disabled')) {
                    element.removeAttribute('disabled')
                }
    
                if (element.nodeName == 'A' && element.classList.contains('disabled')) {
                    element.classList.remove('disabled')
                }
            }
        });
    }
}()